import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app-item-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HelloWorld = _resolveComponent("HelloWorld")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appList, (el) => {
      return (_openBlock(), _createBlock(_component_HelloWorld, {
        key: el.id,
        order: el.last_order || {},
        app: el
      }, null, 8, ["order", "app"]))
    }), 128))
  ]))
}