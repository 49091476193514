
import { Options, Vue } from 'vue-class-component';

const loanStatusMapping: { [k: string]: string } = {
  overdue: 'Overdue',
  repay: 'Waiting for repayment',
  fail_lend: 'Disbursement Failure',
  pass_final: 'Approval passed!',
  withdraw_expired: 'Withdrawal expired',
  wait_withdraw: 'Waiting for claiming',
  audit: 'Approving',
  init: 'Approving',
  pass_1: 'Approving',
  clear: 'paid',
  first_goback: 'Need Supplement',
}

@Options({
  props: {
    order: Object,
    app: Object
  }
})
export default class HelloWorld extends Vue {
  order!: any
  app!: any

  get orderStateText(): string {
    return this.formatLoanStatusText(this.order.loan_status)
  }

  get orderState(): number {
    const { order: { loan_status } } = this;
    if (!loan_status) {
      return 0;
    }
    if (['init', 'audit', 'pass_1', 'fail_final' ].indexOf(loan_status) >= 0) {
      return 1;
    }
    if ('pass_final' == loan_status) {
      return 2;
    }
    if (['overdue', 'repay'].indexOf(loan_status) >= 0) {
      return 3;
    }
    if ('fail_lend' == loan_status) {
      return 4;
    }
    if ('first_goback' == loan_status) {
      return 5;
    }
    return 0;
  }

  openView(el: any) {
    window.location.href = el.url;
  }
  openRepay(el: any) {
    window.location.href = el.url;
  }
  formatLoanStatusText(src: string): string {
    return loanStatusMapping[src] || 'Approval failed';
  }
}
